html {
    scroll-padding-top: 140px !important; /* height of sticky header */
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

html, body {
    height: 100%;
    font-family: Inter, sans-serif;
}

body {
    display: flex;
    flex: 1;
    padding: 0 !important;
    margin: 0 !important;
}

#root {
    display: flex;
    flex: 1;
    line-height: 1.35;
}

p {
    margin-block-end: 0;
}

a {
    text-underline-offset: 0.25em;
    text-decoration: underline;
    text-decoration-thickness: .05em !important;

    &:hover {
        text-decoration: none;
    }
}

b {
    font-weight: 540;
}

.ant-btn-lg {
    padding: 24px 21px !important;
}

.ant-modal-mask {
    z-index: 11 !important;
}

.ant-modal-wrap {
    z-index: 12 !important;
}

.ant-collapse {
    background: transparent;
}

.top-submenu.open {
    max-height: 1000px;
    border-bottom: solid 1px #EEEEEE;
}

.top-submenu-overlay.open {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, visibility 0s
}
