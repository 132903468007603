.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right {
    display: none;
}

.mapboxgl-map {
    font: unset !important;
}

.mapboxgl-popup-content {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.mapboxgl-popup-anchor-top {
    padding-top: 5px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content::after {
    position: absolute;
    left: calc(50% - 20px);
    top: -15px;
    content: '';
    width: 40px;
    height: 15px;
}

.mapboxgl-popup-anchor-right {
    padding-right: 10px;
}

.mapboxgl-popup-anchor-right > .mapboxgl-popup-content::after {
    position: absolute;
    right: -15px;
    top: calc(50% - 20px);
    content: '';
    width: 15px;
    height: 40px;
}

.mapboxgl-popup-anchor-bottom {
    padding-bottom: 10px;
}

.mapboxgl-popup-anchor-bottom > .mapboxgl-popup-content::after {
    position: absolute;
    left: calc(50% - 20px);
    bottom: -15px;
    content: '';
    width: 40px;
    height: 15px;
}

.mapboxgl-popup-anchor-left {
    padding-left: 10px;
}

.mapboxgl-popup-anchor-left > .mapboxgl-popup-content::after {
    position: absolute;
    left: -15px;
    top: calc(50% - 20px);
    content: '';
    width: 15px;
    height: 40px;
}

.map-disable-events .mapboxgl-marker {
    pointer-events: none !important;
}
